<template>
  <b-container>

    <div class="d-flex justify-content-left my-4">
      <h4>{{staticText.callSummaryDisplayName}}</h4>
    </div>

    <div>
      <call-summary
        :call-id="callId"
        :print-view="true"
      ></call-summary>
    </div>

  </b-container>
</template>

<script>

import CallSummary from "@/apps/call_history/CallSummary"

export default {
  name: "CallPrintView",
  components: { CallSummary },
  data: function () {
    return {
      staticTextDefault: {
        callSummaryDisplayName: "Call Summary"
      }
    }
  },
  computed: {
    callId () {
      return parseInt(this.$route.params.callId)
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>
<style>
  body {
    overflow: auto !important;
  }
</style>
